@import "assets/styles/_colors";

.MergeControl {

    &>div {
        padding: 5px;
        margin: 1em auto;
        cursor: pointer;
        opacity: .7;

        &:hover {
            opacity: 1;
        }
    }

    svg { 
        width: 1.3em;
        height: 1.3em; 
    }

    &-rightArrow {
        svg { transform: rotate(-90deg); path { fill: green; }}
    }

    &-leftArrow {
        svg { transform: rotate(90deg); }
    }
}