@import "../../assets/styles/_colors";
@import "../../assets//styles/_fonts";
@import "../../assets/styles/_variables";

.SideMenu {
  width: 260px;
  flex-shrink: 0;
  background-color: $DARK_700;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: auto;

  .logo {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: initial;

    img {
      max-width: 100px;
    }
  }

  &-list {
    padding: 0 1.5rem;
  }

  &-item {
    margin-bottom: 1.25rem;
    // border: 1px solid green;
  }

  &-link {
    display: flex;
    border: 1px solid transparent;
    padding: 0.45rem 0;
    align-items: center;
    color: $WHITE_700;
    text-decoration: none;
    transition: color 0.3s;
    @extend %regular-font-md;

    svg {
      width: 24px;
      height: 24px;
      box-sizing: initial;
      padding: 0 1.5rem 0 0.5rem;
      path {
        fill: $WHITE_700;
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: $WHITE_100;
      box-shadow: none;

      svg {
        path {
          fill: $WHITE_100;
        }
      }
    }

    &.active {
      color: $WHITE_100;
      background-color: $DARK_500;
      border-radius: $BD_RADIUS_4;
      border: 1px solid $DARK_300;

      svg {
        path {
          fill: $ORANGE_500;
        }
      }
    }
  }

  .submenu {
    position: relative;
    margin-bottom: 2.5rem;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 1px solid $DARK_50;
      left: 0;
      bottom: -1.5rem;
    }

    &-active {
      border-bottom: 1px solid $DARK_50;
      padding-bottom: 0.5rem;
      border: 1px solid $DARK_300;

      & > .SideMenu-link {
        background-color: transparent;
        border: 1px solid transparent;
        color: $WHITE_100;
        svg {
          path {
            fill: $WHITE_100;
          }
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      .SideMenu-link {
        padding: 0.45rem 0;
        margin-bottom: 0.5rem;
      }
    }
  }

  &-footer {
    margin-top: auto;
    text-align: center;
    padding: 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;

    a {
      @extend %regular-font-md;
      text-decoration: underline;
      &:hover { 
        cursor: pointer;
        box-shadow: initial;
      }
    }
    p {
      @extend %regular-font-md;
      color: $WHITE_700;
    }
  }

  &-selectOption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;
    align-items: center;
  }

  &-exitLink {
    @extend %regular-font-md;
    color: $WHITE_700;
    svg {
      path {
        fill: #999999;
        ;
      }
    }
    &:hover {
      color: $WHITE_100;
      box-shadow: none;

      svg {
        path {
          fill: $WHITE_100;
        }
      }
    }
  }


  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    z-index: 2;
    height: 100vh;
    width: 100%;
    max-width: 260px;
    overflow: auto;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    left: -260px;
    transition: .5s left;
    &.active {
      left: 0px;
      transition: .5s left;
    }
    &-footer {
      margin-bottom: 10em;
    }
  }
}
