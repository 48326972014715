@import "assets/styles/_colors";
@import "assets/styles/_variables";

.TextInput {
  padding: 0.6em 1em;
  min-height: 2.75em;

  &.withIcon {
    padding: 0.6em 2em;
  }

  &-labelCover {
    // position: relative;
    // display: block;

    position: relative;
    display: block;
    flex: 0 0 100%;
  }

  &-icon,
  &-reset {
    position: absolute;
    top: 50%;
    width: 2em;
    transform: translateY(-50%);

    & > svg {
      width: 1em;
      height: 1em;
    }
  }

  &-icon {
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-reset {
    right: 0.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  textarea {
    background-color: $DARK_100;
    line-height: 1.3em;
  }
}
