@import "./_variables";

@mixin grid-12 {
    &-12 {
        width: 100%;
    }
    &-11 {
        width: 91.66667%;
    }
    &-10 {
        width: 83.33333%;
    }
    &-9 {
        width: 75%;
    }
    &-8 {
        width: 66.66667%;
    }
    &-7 {
        width: 58.33333%;
    }
    &-6 {
        width: 50%;
    }
    &-5 {
        width: 41.66667%;
    }
    &-4 {
        width: 33.33333%;
    }
    &-3 {
        width: 25%;
    }
    &-2 {
        width: 16.66667%;
    }
    &-1 {
        width: 8.33333%;
    }
}

.row {
    display: inline-block;
    width: 100%;
    position: relative;
    clear: both;
}

.col {
    box-sizing: border-box;
    position: relative;
    padding: 0 .5em 1em;
    width: 100%;
    float: left;
    @include grid-12;

    &-sm {
        @media screen and (min-width: $sm) {            
            @include grid-12;
        }
    }
    &-md {
        @media screen and (min-width: $md) {            
            @include grid-12;
        }
    }
    &-lg {
        @media screen and (min-width: $lg) {            
            @include grid-12;
        }
    }
    &-xl {
        @media screen and (min-width: $xl) {            
            @include grid-12;
        }
    }
}


.hidden {
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
}