@import "assets/styles/_colors";

.PickemLayoutPage {
  height: 100%;
  width: 100%; 
  display: flex;
  flex-direction: column;
  background-color: $DARK_500;
  overflow-y: scroll;

  &-content {
    position: relative;
    height: 100%;
    //margin-top: 8px;
  }

  &-contentWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    padding-bottom: 3em;
  }

  &-contentInnerWrap {
    position: relative;
    padding: 32px 32px;
    max-width: 1200px;
  }

  @media (max-width: 991px) {
    padding-top: 50px;

    &-contentInnerWrap {
      padding: 32px;
    }

    &-contentWrap {
      padding-bottom: 0;
    }
  }
}
