@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.PickemSchedulePage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }

    &-weeks {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-buttonSelected {
        background-color: orange;
    }

    &-buttonNotSelected {
        background: transparent
    }   

    &-weekView {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-weekButton {
        height: 25px;
        width: 25px;
        color: #cdd6da;
        
    }

    h1 {
        text-align: center;
        margin: 2em auto .5em;
        &.adjusted { 
            padding-right: 3em;
        }
        text-transform: capitalize;
    }

    h3 { text-align: center;  }
    h4 { color: $GRADIENT_1; margin: 0 0 1em; }

    &-segment {
        display: flex;
        justify-content: space-between;
        padding: .5em;
        background: $DARK_700;
        margin: 1px;
    }

    &-table {
        display: flex;
        justify-content: center;
        margin: auto
    }

    &-teamNameHome {
        @extend %regular-font-md;
        min-width: 15em;
        max-width: 15em;
        text-align: center;
    }

    &-teamNameAway {
        @extend %regular-font-md;
        min-width: 15em;
        max-width: 15em;
        text-align: center;
    }

    &-teamScore {
        @extend %regular-font-md;
        min-width: 3.5em;
        max-width: 3.5em;
        text-align: center;
    }
}