@import "assets/styles/_colors";

.SelectPathPage {
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    padding: 0;
    

    &-logo {
        img {
            display: block;
            margin: auto;
            border-radius: 20%;
        }
        margin-bottom: 15px;
    }

    &-optionBox {
        width: 150px;
        justify-content: center;
    }

    &-selectView {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 25%;
        min-width: 15%;
        vertical-align: middle;
    }
    @media (max-width: 991px) {
        &-selectView {
          flex-direction: column;
          align-items: center;
          margin: auto;
          vertical-align: middle;
        }

        &-optionBox {
            justify-content: center;
            margin: 2.5em 0;
        }
      }

    &-btn {
        width: 100%;
    }

    &-error {
        text-align: center;
        color: $RED_900;
    }
}
