.FetchSettings {
    &-p {
        line-height: 2.75em;
        text-align: right;
    }

    .Select-input.has-value {
        text-transform: uppercase;
    }


    .uppercase .Checkbox-label {
        text-transform: uppercase;
    }
}