.PickemCreatePage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }

    &-leagueinfo {
        min-width: 35vw;
        max-width: 35vw;
    }

    @media (max-width: 991px) {
        &-leagueinfo {
            min-width: 100%;
        }
      }
}