@import "assets/styles/_variables";
@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.UserPage {
    @extend %regular-font-sm;

    &-secondaryControl {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1em;
    }

    &-table {
        @extend %regular-font-lg;
    }

    h1 {       
        text-align: center;
        margin: 1em auto .5em;
        &.adjusted { 
            padding-right: 3em;
        }
    }
}

.UserComparePage {
 
    width: 100%;
    clear: both;
    position: absolute;
    left: 0;
    top: 0;

    .User1, .User2 { width: 50%; float: left; }
    .User1 { border-right: 1px solid; }

    .UserMergeBtn {
        display: inline-block;
        clear: both;
        width: 100%;
        text-align: center;

        &>div { display: inline-block; }

        svg { 
            cursor: pointer;
            padding: 1rem;
            width: 4rem;
            height: 4rem;
            &>path { fill: $RED_500; }
            &:hover > path { fill: $RED_900; }
        }
    }
}