.OverlaySpinner {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
    display: none;
    animation: ToggleShow .8s backwards ease-in-out;

    &.active {
        display: block;
        animation: ToggleShow .8s forwards ease-in-out;
    }

    &>div {
        position: relative;
        width: 100%;
        height: 100%;
    }
}


@keyframes ToggleShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}