@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.TeamData {
    @extend %regular-font-sm;

    h1 { 
        text-align: center;
        margin: 2em auto .5em;
    }

    h3 { text-align: center;  }
    h4 { color: $GRADIENT_1; margin: 1em 0; }

    &-segment {
        display: flex;
        justify-content: center;
        padding: .5em;
        background: $DARK_700;
        margin: 1px;

        &.Field { text-transform: capitalize; font-weight: 600; justify-content: flex-end; }
    }

    .TeamMergeBtn {
        display: inline-block;
        clear: both;
        width: 100%;
        text-align: center;

        &>div { display: inline-block; }

        svg { 
            cursor: pointer;
            padding: 1rem;
            width: 4rem;
            height: 4rem;
            &>path { fill: $RED_500; }
            &:hover > path { fill: $RED_900; }
        }
    }
}