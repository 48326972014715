@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.UserBets {
    @extend %regular-font-md;
    h1 {
        text-align: center;
        margin: 2em auto .5em;
        &.adjusted { 
            padding-right: 3em;
        }
    }

    h3 { text-align: center;  }
    h4 { color: $GRADIENT_1; margin: 0 0 1em; }

    &-segment {
        display: flex;
        justify-content: space-between;
        padding: .5em;
        background: $DARK_700;
        margin: 1px;
    }
}