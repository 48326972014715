.TeamMapping {
    &:before {
        display: inline-block;
        content: "Mapping";
        position: absolute;
        right: 1em;
        top: 1em;
    }

    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: rgba(0,0,0,.4);
    padding: 2em 0 1em;
}