@import "assets/styles/_colors";
@import "assets/styles/_variables";

.Checkbox {
  width: 100%;
  display: inline-block;

  &-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .5em;

    &-name {
      margin-left: .5em;
    }
  }

  &-input {
    position: fixed;
    left: -100%;
    z-index: -1;
  }

  &-input:focus + &-label,
  &-input:hover + &-label {
    background-color: $DARK_100;
    border-radius: $BD_RADIUS_4;
  }

  &.simple-select {
    .Checkbox-label {
      flex-direction: row-reverse;
      justify-content: space-between;

      &-name {
        width: 100%;
      }

      svg {
        height: 1em;
      }
    }
  }
}
