@import "assets/styles/_colors";

@keyframes Open {
    0% {
        max-height: 0px;
        top: -100px;
    }
    10% {
        max-height: 100px;
    }
    100% {
        top: 0;
        max-height: 100px;
    }
}
@keyframes Close {
    0% {
        top: 0px;
        max-height: 100px;
    }
    90% {
        max-height: 100px;
    }
    100% {
        top: -100px;
        max-height: 0px;
    }
}

.FlashMessage {
    width: 100%;
    text-align: center;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    max-height: 0;
    transition: 0.5s all;
    top: 0;

    & > div {
        width: 100%;
        max-width: 400px;
        margin: 1em auto;
        padding: 0.5rem 15px;
        border-radius: 3px;
        background: $ORANGE_500;
        color: #fff;
    }

    &.success > div {
        background: $GREEN_900;
    }

    &.error > div {
        background: $RED_700;
    }

    &.isHidden {
        opacity: 0;
        position: fixed;
        transition: 0.5s all;
        top: -100%;
    }

    animation: Close 0.5s forwards ease-in-out;

    &.active {
        animation: Open 0.5s forwards ease-in-out;
    }
}
