.LoadingPage {
    position: fixed;
    left: 0;
    top: 0; 
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-spinner.spinner {
        width: 2em;
        height: 2em;
    }
}