@import "assets/styles/_colors";

.GridEvent {
    display: flex;
    flex-direction: column;

    & > div {
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        background-color: #333;
    }
    &-label {
        color: $WHITE_400;
        width: 130px;
        align-self: center;
        margin-right: 1em;
    }
    &-value {
        background-color: $DARK_50;
        padding: 8px 10px;
        border-radius: 4px;
        width: 100%;
        &.isMatch {
            background-color: green;
        }
    }

    &.matchEvent {
        & > div {
            background: none;
        }
        .GridEvent-label {
            display: none;
        }
        .GridEvent-value {
            opacity: .6;
        }
    }
}
