// Text
$WHITE_100: #f5f5f5;
$WHITE_300: #ebebeb;
$WHITE_400: #d8d8d8;
$WHITE_500: #c8c8c8;
$WHITE_700: #999999;
$WHITE_900: #7b7a79;
$WHITE_950: #444444;

//BG
$DARK_900: #09192c;
$DARK_700: #0e2239;
$DARK_600: #112740;
$DARK_500: #142b48;
$DARK_300: #193558;
$DARK_100: #1e3f67;
$DARK_50: #2a578d;

//Primary
$ORANGE_900: #f24718;
$ORANGE_700: #f1562c;
$ORANGE_500: #f86d4c;
$ORANGE_300: #ff8c71;
$ORANGE_100: #ffb3a1;

$DISABLED: #09192c;

//State
$RED_900: #ff3b3b;
$RED_700: #ff5c5c;
$RED_500: #ff8080;
$RED_300: #ff9999;
$RED_100: #ffb0b0;

$GREEN_900: #06c270;
$GREEN_700: #39d98a;
$GREEN_500: #57eba1;
$GREEN_300: #80f4b2;
$GREEN_100: #a0fbc7;

$YELLOW_900: #ffcc00;
$YELLOW_700: #fddd48;
$YELLOW_500: #fded72;
$YELLOW_300: #fff49d;
$YELLOW_100: #fff6af;

$GRADIENT_1: #faa831;
$GRADIENT_2: #df2b30;