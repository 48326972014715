@import "assets/styles/_colors";

.HomePage {

    h1 {}
    
    p { padding: 1em 0 2em; }

    div { padding: .5em 0 .5em; }

    ul { padding: .5em 0; }

    li { list-style: inside; line-height: 1.4em; color: $WHITE_500; }

}