
$font-size-base: 17;

//Regular font
%regular-font {
  font-family: RubikRegular;
  line-height: 110%;
}

$font-sizes-regular: (
  "xs": 10,
  "sm": 13.6,
  "md": 17,
  "lg": 21.25,
  "xl": 59.5,
);

@each $name, $size in $font-sizes-regular {
  %regular-font-#{$name} {
    @extend %regular-font;
    font-size: $size / $font-size-base + em;
  }
}

// Medium font
%medium-font {
  font-family: RubikMedium;
  line-height: 110%;
}

$font-sizes-medium: (
  "xs": 10,
  "sm": 13.6,
  "md": 17,
  "lg": 21.25,
  "xl": 25.5,
  "xxl": 30
);

@each $name, $size in $font-sizes-medium {
  %medium-font-#{$name} {
    @extend %medium-font;
    font-size: $size / $font-size-base + em;
  }
}

// Light font
%light-font {
  font-family: RubikLight;
  line-height: 150%;
}

$font-sizes-light: (
  "sm": 13.6,
  "md": 17,
  "lg": 21,
  "xl": 27 
  );

@each $name, $size in $font-sizes-light {
  %light-font-#{$name} {
    @extend %light-font;
    font-size: $size / $font-size-base + em;
  }
}
