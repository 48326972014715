@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.PickemGroupPicksPage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }

    a {
        color: black;
    }

    &-weeks {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-currentWeek {
        background-color: $ORANGE_500;
        color: $WHITE_500;
    }

    &-notCurrentWeek {
        background: transparent
    }   

    &-weekView {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-weekButton {
        color: #cdd6da;
        padding: 0.25em;
        width: 1.25em;
        height: 1.25em;

        &:hover {
            border: 1px solid $ORANGE_300;
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5),
                0px 0px 10px rgba(241, 86, 44, 0.2);
            }
    }

    &-td-win {
        min-width: 3.5em;
        max-width: 3.5em;
        padding: 0.25em;
        text-align: center;
        border: 1px solid black;
        border-collapse: collapse;
        white-space: nowrap;
        align-items: center;
        background-color: lightgreen;
        color: green;
    }

    &-td-lose {
        min-width: 3.5em;
        max-width: 3.5em;
        padding: 0.25em;
        text-align: center;
        border: 1px solid black;
        border-collapse: collapse;
        white-space: nowrap;
        align-items: center;
        background-color: lightpink;
        color: red;
    }

    &-td-label {
        width: 15em;
        min-width: 15em;
        padding: 0.5em;
        border: 1px solid black;
        border-collapse: collapse;
        position: sticky;
        left: 0;
    }

    &-td {
        min-width: 3.5em;
        max-width: 3.5em;
        padding: 0.25em;
        text-align: center;
        border: 1px solid black;
        border-collapse: collapse;
        white-space: nowrap;
        align-items: center;
    }

    &-table {
        border: 1px solid black;
        border-collapse: collapse;
        margin-bottom: 1em;
        background-color: white;
        color: black;
        overflow-x: auto;
        table-layout: fixed
    }

    @media (max-width: 991px) {
        &-td-label {
            width: 12em;
            min-width: 12em;
            padding: 0.25em;
            border: 1px solid black;
            border-collapse: collapse; 
            font-size: 10;
        }

        &-td {
            max-width: 3.5em;
            min-width: 3.5em;
            padding: 0.25em;
            text-align: center;
            border: 1px solid black;
            border-collapse: collapse;
            white-space: nowrap;
            align-items: center;
            font-size: 10
        }

        &-td-win {
            max-width: 3.5em;
            min-width: 3.5em;
            padding: 0.25em;
            text-align: center;
            border: 1px solid black;
            border-collapse: collapse;
            white-space: nowrap;
            align-items: center;
            background-color: lightgreen;
            color: green;
            font-size: 10
        }
    
        &-td-lose {
            max-width: 3.5em;
            min-width: 3.5em;
            padding: 0.25em;
            text-align: center;
            border: 1px solid black;
            border-collapse: collapse;
            white-space: nowrap;
            align-items: center;
            background-color: lightpink;
            color: red;
            font-size: 10
        }
      }


}