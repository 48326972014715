.TeamPage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }
}

.TeamComparePage {
 
    width: 100%;
    clear: both;
    position: absolute;
    left: 0;
    top: 0;

    .TableGap { width: 20%; float: left;}
    .Team1, .Team2 { width: 40%; float: left; }
    .Team1 { border-right: 1px solid; }
}