.Breadcrumbs {
    display: flex;
    align-items: center;

    a,
    div {
        text-transform: capitalize;
        text-decoration: none;
        font-size: 0.8em;
    }

    a:hover {
        box-shadow: initial;
        text-decoration: underline;
    }

    svg {
        height: 0.3em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        transform: rotate(-90deg);
    }
}
