@import "assets/styles/_colors";

.DuplicateSearch {

    &-content {
        margin: 2rem auto 0;
    }

    &-buttons {
        display: flex;
        justify-content: space-between;

        button {

            background-color: $DARK_100;

            &.primary {
                background-color: $ORANGE_500;
            }
        }
    }

    &-Filter {
        float: right;
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        span { margin-right: 1rem; }
    }

    .Search-button {
        width: 100%;
    }
}