@import "assets/styles/_colors";

.withDialog {

    display: none;

    h2 {
        text-align: center;
        width: 100%;
        margin: 2rem auto 0;
    }

    &-shadow { 
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.8);
    }

    .content {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        text-align: center;
        width: 100%;
        max-width: 25rem;
        margin: auto;
        padding: 1em;
        background: $DARK_500;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
    }

    .closeButton {
        position: absolute;
        right: 1em;
        top: 1em;
        cursor: pointer; 
        
        svg {
            fill: $WHITE_100;
        }
    }

    &.active {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;

        z-index: 9;
        padding: 2em 1em;
        overflow: auto;
    }
}