@import "../fonts/Rubik/Rubik-Regular.ttf";

@font-face {
  font-family: "RubikRegular";
  src: url("../fonts/Rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: "RubikMedium";
  src: url("../fonts/Rubik/Rubik-Medium.ttf");
}

@font-face {
  font-family: "RubikLight";
  src: url("../fonts/Rubik/Rubik-Light.ttf");
}

@keyframes loadingSpinner {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@import "./_fonts";
@import "./_colors";
@import "./_variables";
@import "./_buttons";
@import "./_inputs";
@import "./_grid";
@import "./_table";


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// html,
// body {
//   font-size: 17px;
// }

body {
  font-size: 1.064rem;
  color: $WHITE_100;
  overflow: hidden;
}

li {
  list-style-type: none;
}

a {
  @extend %regular-font-sm;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  color: $ORANGE_500;

  &:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.6),
      0px 0px 10px rgba(241, 86, 44, 0.5);
    color: $ORANGE_700;
  }

  &:active,
  &:focus {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1),
      0px 0px 4px rgba(241, 86, 44, 0.1);
    color: $ORANGE_900;
    outline: none;
  }
}

.tag {
  @extend %regular-font-sm;
  display: inline-block;
  border: 1px solid $DARK_50;
  background: $DARK_100;
  color: $WHITE_100;
  padding: 0.25em 0.5em;
  margin: 0 0.25em;
  border-radius: 1em;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
}

.toolkit {
  @extend %regular-font-sm;
  position: absolute;
  background-color: $DARK_900;
  border: 1px solid $DARK_600;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8), 0px 0px 4px rgba(241, 86, 44, 0.1);
  border-radius: $BD_RADIUS_4;
  color: $WHITE_100;
  text-align: center;

  left: 50%;
  transform: translateX(-50%);
  bottom: -3em;
  z-index: 1;
  padding: 0.6em;
  white-space: nowrap;

  display: none;

  &:before {
    content: "";
    position: absolute;
    width: 0.6em;
    height: 0.6em;
    background-color: $DARK_900;
    border-left: 1px solid $DARK_600;
    border-top: 1px solid $DARK_600;
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotateZ(45deg);
    z-index: -1;
  }
}


.control-bar {
  display: flex;
  justify-content: space-between;
}

.app-wrap {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .left { text-align: left; }
  .right { text-align: right; }
  .center { text-align: center; }    
}

.content-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
}

.spinner {
  width: 1em;
  height: 1em;
  display: inline-flex;
  margin-right: 1em;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3rem solid rgba(204, 204, 204, 0.4);
  border-right: 0.3rem solid rgba(204, 204, 204, 0.4);
  border-bottom: 0.3rem solid rgba(204, 204, 204, 0.4);
  border-left: 0.3rem solid $WHITE_500;
  border-radius: 50%;
  animation: loadingSpinner 1s infinite linear;
}

h1 { @extend %medium-font-xxl; }
h2 { @extend %medium-font-xl; }
h3 { @extend %light-font-xl; }
h4 { @extend %medium-font-md; } 
span, li, p {  
  @extend %regular-font-md;
}

.uppercase {
  text-transform: uppercase;

  * { text-transform: uppercase; }
}