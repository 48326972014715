@import "assets/styles/_colors";
@import "assets/styles/_variables";

.SelectModeButton {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5em;
    svg {
        fill: $WHITE_100;
    }

    &.active {
        svg {
            fill: $ORANGE_500;
        }
    }
}

.TableSubtitle {
    display: inline-block;
    justify-content: center;
    vertical-align: middle;
    svg {
        fill: $WHITE_100;
        margin-right: 10px;
    }
}

.TablePagination {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 60px;
    position: relative;
    font-size: 14px;

    & > div {
        float: right;
        height: 100%;
        display: flex;
        align-items: center;
        color: $WHITE_300;
    }

    button {
        appearance: none;
        background: none;
        border: none;
        padding: 0;
        line-height: 1;
        cursor: pointer;
        opacity: 0.4;

        svg {
            fill: $WHITE_300;
        }

        &:disabled {
            opacity: 0.1;
        }
    }

    button + button {
        padding-left: 20px;
    }

    .PerPageLabel {
        &:before {
            content: "Rows:";
        }
        @media (min-width: 600px) {
            &:before {
                content: "Rows per page:";
            }
        }
    }

    select {
        margin-left: 1em;
        border-radius: $BD_RADIUS_4;
        padding: 0.6em 1em;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
        background-color: $DARK_100;
        border: 1px solid $DARK_300;
        color: $WHITE_300;

        option {
            color: $WHITE_300;
        }
    }

    @media (min-width: 992px) {
        float: right;
        max-width: 450px;
    }
}

.TableSubtitle {
    display: flex;
    align-items: center;
    button.primary {
        background-color: $ORANGE_500;
    }

    & > div {
        display: flex;
        width: 100%;
        align-items: center;

        span {
            display: flex;
            align-self: center;
        }
    }
}
