@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.EventMisc {
    @extend %regular-font-sm;

    h1 { 
        text-align: center;
        margin: 2em auto .5em;
    }

    h3 { text-align: center;  }
    h4 { color: $GRADIENT_1; margin: 1em 0; }

    &-segment {
        display: flex;
        justify-content: space-between;
        padding: .5em;
        background: $DARK_700;
        margin: 1px;
    }
}