.PickemStandingsPage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }

    &-weeks {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-buttonSelected {
        background-color: orange;
    }

    &-buttonNotSelected {
        background: transparent
    }   

    &-weekView {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-weekButton {
        height: 25px;
        width: 25px;
        color: #cdd6da;
        
    }
}