@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.PickemAccountPage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }


    h1 {
        margin: 2em auto .5em;
        &.adjusted { 
            padding-right: 3em;
        }
        text-transform: capitalize;
    }

    h3 { text-align: center;  }
    h4 { color: $GRADIENT_1; margin: 0 0 1em; }

    &-segment {
        display: flex;
        justify-content: space-between;
        padding: .5em;
        background: $DARK_700;
        margin: 1px;
    }

    &-accountinfo {
        min-width: 35vw;
        max-width: 35vw;
    }

    @media (max-width: 991px) {
        &-accountinfo {
            min-width: 100%;
        }
      }
}