@import "assets/styles/_variables";
@import "assets/styles/_colors";

.EventPage {
    
    &-secondaryControl {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1em;
    }
}

.EventComparePage {
 
    width: 100%;
    clear: both;
    position: absolute;
    left: 0;
    top: 0;

    .Event1, .Event2 { width: 50%; float: left; }
    .Event1 { border-right: 1px solid; }

    .EventMergeBtn {
        display: inline-block;
        clear: both;
        width: 100%;
        text-align: center;

        &>div { display: inline-block; }

        svg { 
            cursor: pointer;
            padding: 1rem;
            width: 4rem;
            height: 4rem;
            &>path { fill: $RED_500; }
            &:hover > path { fill: $RED_900; }
        }
    }
}