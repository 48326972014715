@import "assets/styles/_colors";

.SpinnerWrap {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px; 
    
    .spinner {
        width: 2em;
        height: 2em;
        justify-content: center;
    }

    .spinner-message {
        color: #fff;
        padding: .1em;
        font-size: 1.5rem;
    }

    &>div { 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}