@import "assets/styles/_variables";
@import "assets/styles/_colors";

.TournamentPage {
    
    &-secondaryControl {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1em;
    }
}