@import "../../assets/styles/_colors";
@import "../../assets/styles/_fonts";

.PickemHeader {
  width: 100%;
  padding: 1.25em 3.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $DARK_50;

  &-title {
    @extend %medium-font-xxl;
    color: $WHITE_100;
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    justify-content: space-between;

    button { 
      font-size: 1rem;
    }
  }

  &-subtitle {
    @extend %regular-font-xs;
    margin-left: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &-tasks {
    @extend %medium-font-sm;
    color: $GREEN_900;
    display: flex;
    flex-direction: column;

    span {
      @extend %light-font-sm;
    }
  }

  &-nameview {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 0.5em
  }

  &-disabledName {
    color: #A9A9A9;
  }

  &-name {
    @extend %regular-font-md;
    margin-left: auto; 
    margin-right: 0; 
  }

  &-competitionName {
    @extend %regular-font-sm;
    margin-left: auto; 
    margin-right: 0;
  }

  &-competitionDescription {
    @extend %regular-font-xs;
    margin-left: auto; 
    margin-right: 0;
  }

  &-mobileDevice {
    margin-top: 11em;
  }

  @media (max-width: 991px) {
    padding: 1.25em;
    font-size: 0.6em;

    &-subtitle {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }

    button {
      margin-left: 0.5em
    }

  }
}
