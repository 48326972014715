@import "assets/styles/_colors";

.PickemHomePage {
    
    p { padding: 1em 0 2em; }

    div { padding: .5em 0 .5em; }

    ul { padding: .5em 0; }

    li { list-style: inside; line-height: 1.4em; color: $WHITE_500; }

    .Table th {
        vertical-align: bottom;
    }

    @media (max-width: 991px) {
        .Table td {
            min-width: 50vw;
        }
        .Table th {
            vertical-align: bottom;
            min-width: 50vw;
        }
      }
    

    &-buttonview {
        display: flex;
        flex-direction: row;
    }

    &-leagueActionView {
        display: flex;
        flex-direction: row;
    }

}