// UI Shadows
$boxShadowArea: -4px 0px 8px rgba(0, 0, 0, 0.1),
  -4px 0px 16px rgba(0, 0, 0, 0.1);
$boxShadowDropDown: 0px 0px 8px rgba(0, 0, 0, 0.8),
  0px 0px 4px rgba(133, 59, 39, 0.1);
$boxShadowCards: 0px 0px 8px rgba(0, 0, 0, 0.3);

//UI corner radius
$BD_RADIUS_2: 0.11em; // 2px; //cards
$BD_RADIUS_4: 0.23em; // 4px; //button, dropdown, input
$BD_RADIUS_6: 0.35em; // 6px; //small button
$BD-RADIUS-8: 0.47em; //8px;

//Spacing
$space_0_5: 0.5rem; //rem loop
$space_1: 1rem;
$space_2: 2rem;
$space_2_5: 2.5rem;
$space_4: 4rem;

// Grid
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
