@import "assets/styles/_colors";

.LoginPage {
    width: 100%;
    max-width: 350px;
    margin: auto;

    &-logo {
        img {
            display: block;
            margin: auto;
            border-radius: 20%;
        }
    }

    &-btn {
        width: 100%;
    }

    &-error {
        text-align: center;
        color: $RED_900;
    }

    .TextInput { padding: .7em 1em; }
}
