@import "assets/styles/_colors";
@import "assets/styles/_fonts";
@import "assets/styles/_variables";

.TeamHeader {
    position: relative;
    border-bottom: 2px solid #444;
    box-shadow: 0px 1px 4px #000;
    overflow: hidden;

    &-top {
        background: linear-gradient( $GRADIENT_1,  $GRADIENT_2);
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1em;
        @extend %regular-font-lg;
        text-transform: uppercase;
    }
    &-bottom {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 2em 1em;
        background: $DARK_700;
        h3 {
            transform: scale(1, 1.4);
            letter-spacing: 1px;
            text-transform: capitalize;
        }
    }
    &-vsMarker {
        position: absolute;
        top: 0;
        left: calc(50% - 75px);

        &:before {
            content: " ";
            display: block;
            position: relative;
            border-top: 25px solid #103c6c;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            height: 0;
            width: 45px;
        }
        &:after {
            position: absolute;
            content: "vs";
            @extend %regular-font-sm;
            line-height: 1.5em;
            display: inline-block;
            top: 0;
            width: 100%;
            text-align: center;
        }
    }

    &.isComparison {
        .TeamHeader-top { font-size: 1em; }
        
        h3 { max-width: calc(50% - 1em); font-size: 1rem; }

        .TeamHeader-vsMarker {
            position: relative;
            top: 0;
            left: 0;
    
            &:before {
                border-left: none;
                border-right: none;
                border-radius: 5px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .TeamHeader-top { font-size: 1em; }
        
        h3 { max-width: calc(50% - 1em); font-size: 1rem; }

        .TeamHeader-vsMarker {
            position: relative;
            top: 0;
            left: 0;
    
            &:before {
                border-left: none;
                border-right: none;
                border-radius: 5px;
            }
        }
    }
}