@import "assets/styles/_variables";
@import "assets/styles/_colors";

//.ConflictHolder.row {
//    max-width: 900px;
//}
.ConflictLeft.col {
    width: 50%;
    .MergeControl {
        display: none;
    }
    &.merging {
        .MergeControl { display: flex; }
        .GridEvent {
            width: 81.81818%;
            float: left;
        }
        width: 55%;
    }
}
.ConflictRight.col {
    width: 50%;

    &.merging {
        width: 45%;
    }
}

.MergeControl {
    width: 18.18182%;
    height: calc(100% - 2em);
    position: absolute;
    right: -.5em;
    top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlaceHolder.col {
    position: relative;
    min-height: 200px;
    height: 100%;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        height: 100%;
        background: $DARK_700;
        opacity: 0.7;
        position: absolute;
        width: calc(100% - 1em);
    }

    @media screen and (min-width: $sm) {
        height: calc(100% - 1em);
        position: absolute;
        right: 0;
        top: 0;
        background: $DARK_700;
        opacity: 0.7;
        max-width: 370px;

        & > div {
            position: relative;
            width: initial;
            background: none;
            opacity: 1;
        }
    }
}

.clickable {
    cursor: pointer;
}
