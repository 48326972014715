@import "assets/styles/_colors";
@import "assets/styles/_variables";
@import "assets/styles/_fonts";

.MultiSelect {
  position: relative;
  @extend %regular-font-md;

  &-dropDown {
    position: absolute;
    padding: 0.5em;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8),
      0px 0px 4px rgba(241, 86, 44, 0.1);
    border-radius: $BD_RADIUS_4;
    background-color: $DARK_900;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1;
  }

  &-input {
    width: 100%;
    min-height: 2.75em;
    border-radius: $BD_RADIUS_4;
    padding: 0.45em 3em 0.45em 1em;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    background-color: $WHITE_100;
    border: 1px solid $DARK_300;
    color: rgba(0,0,0,.5);
    min-width: 200px;
    display: flex;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    flex-wrap: wrap;

    &:hover {
      border: 1px solid $DARK_500;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4),
        0px 0px 10px $DARK_50;
    }

    &-disabled {
      background-color: $DARK_300;
      border: 1px solid $DARK_500;
      box-shadow: none;
      color: $WHITE_950;
      cursor: not-allowed;
    }

    &-selected.tag{
      display: flex;
      align-items: center;
      line-height: 1em;
      cursor: pointer;

      & > svg {
        margin-left: 0.5em;
        height: 1em;
        width: 1em;
        fill: $WHITE_100;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      width: 2em;
      height: 80%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      text-align: center;
      padding: 0;
      background-color: $WHITE_100;
    }
  }
}
