@import "../../assets/styles/_colors";

.MobileHeader {
    display: none;
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 2;
    background: $DARK_700;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    svg {
        width: 30px;
    }

    &-logo {
        position: relative;
        width: 35px;
        height: 35px;

        .logo {
            display: block;
            font-size: 0;
            height: 100%;
        }

        img {
            height: 100%;
        }

        a:hover {
            cursor: pointer;
            box-shadow: initial;
        }
    }

    &-hamburger {
        cursor: pointer;
        position: absolute;
        right: 1em;
        top: 0;
        height: 100%;
        display: flex;
        margin: auto;
        flex-direction: column;
        justify-content: center;

        svg {
            height: 30px;
            fill: $WHITE_100;
        }

        &.active {
            svg {
                fill: $ORANGE_500;
            }
        }
    }

    @media (max-width: 991px) {
        display: flex;
    }
}


.MobileHeader-shadow {
    display: none;
    width: 100%;
    height: calc(100% - 50px);
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 2;
    overflow: hidden;
    background: rgba(0,0,0,.8);
    transition: .5s all;
    
    @media (max-width: 991px) {
        display: flex;
    }
}