@import "./_colors";
@import "./_fonts";


.Table {
    width: 100%;
    overflow: auto;
    margin: 1em 0;

    table {
        width: 100%;
        border-spacing: 0;
    }

    tr {
        margin: 0px 8px;
        &:hover td {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.05);
        }
    }

    th {
        padding: 10px 5px;
        text-align: left;
        cursor: pointer;
        @extend %regular-font-sm;
        color: $WHITE_700;

        &.unsortable {
            cursor: initial;
        }

        svg {
            display: none;
        }
        &.active svg {
            display: inline;
            margin-left: 5px;
        }
        &.down svg {
            transform: rotate(180deg);
        }

        @media(max-width: 991px) {
            &.mobileHide { display:none; }
        }
    }

    td {
        position: relative;
        padding: 10px 5px;
        word-break: break-all;
        text-align: left;
        @extend %regular-font-sm;

        img {
            max-height: 4em;

            &.thumb {
                width: 1.4em;
                height: 1.4em;
                border-radius: 50%;
            }
        }

        &:hover {
            cursor: pointer;
            a {
                text-decoration: underline;
            }
        }

        @media(max-width: 991px) {
            &.mobileHide { display:none; }
        }
    }

    td > input[type=checkbox], 
    th> input[type=checkbox] { 
        min-width: 1em; 
    }
}