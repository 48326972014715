input {
  width: 100%;
  border-radius: $BD_RADIUS_4;
  padding: 0.6em 1em;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  background-color: $WHITE_100;
  border: 1px solid $DARK_300;

  /* Change the white to any color ;) */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color: $DARK_100 !important;
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05) !important;
    -webkit-text-fill-color: $WHITE_100 !important;
  }

  &:-internal-autofill-selected {
    background-color: transparent;
  }

  @extend %regular-font-md;
  color: black;

  &::placeholder {
    color: $WHITE_900;
  }

  &:hover {
    border: 1px solid $DARK_500;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4),
      0px 0px 10px $DARK_50;
  }

  &:focus,
  &:active {
    outline: rgba(241, 86, 44, 1) outset 1px;
  }

  &:focus {
    outline: none;
    border: 1px solid $ORANGE_100;
  }

  &:disabled {
    background-color: $DARK_300;
    border: 1px solid $DARK_500;
    box-shadow: none;
    color: $WHITE_950;
    cursor: not-allowed;
  }
}

textarea {
  width: 100%;
  max-width: 100%;
  background-color: $DARK_100;
  border-radius: $BD_RADIUS_4;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  color: $WHITE_900;
  border: 1px solid $DARK_300;
  @extend %regular-font-md;
  padding: 0.6em 1em;
}
