button {
  @extend %regular-font-md;
  border-radius: $BD_RADIUS_4;
  padding: 0.6em 1.5em;
  border: 1px solid transparent;
  color: $WHITE_100;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;

  &:focus,
  &:active {
    outline: rgba(241, 86, 44, 1) outset 1px;
  }
  &.spinning:before {
      content: " ";
      width: .5em;
      height: .5em;
      display: inline-flex;
      margin-right: 1em;
      position: relative;
      text-indent: -9999em;
      border-top: 0.3rem solid rgba(204, 204, 204, 0.4);
      border-right: 0.3rem solid rgba(204, 204, 204, 0.4);
      border-bottom: 0.3rem solid rgba(204, 204, 204, 0.4);
      border-left: 0.3rem solid $WHITE_500;
      border-radius: 50%;
      animation: loadingSpinner 1s infinite linear;
  }
}

.btn-primary {
  background: $ORANGE_500;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4),
      0px 0px 10px rgba(241, 86, 44, 0.5);
    background: $ORANGE_700;
    border: 1px solid $ORANGE_700;
  }

  &:active,
  &:focus {
    background-color: $ORANGE_900;
    border: 1px solid $ORANGE_900;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1),
      0px 0px 4px rgba(241, 86, 44, 0.1);
    color: $WHITE_100;
    outline: none;
  }

  &:disabled {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);
    background-color: $DISABLED;
    border: 1px solid transparent;
    cursor: not-allowed;
    color: $WHITE_900;
  }
}

.btn-secondary {
  background-color: $DARK_100;
  border: 1px solid $DARK_300;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  color: $WHITE_300;

  &:hover {
    border: 1px solid $ORANGE_300;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5),
      0px 0px 10px rgba(241, 86, 44, 0.2);
  }

  &:active,
  &:focus {
    background-color: $DARK_500;
    border: 1px solid $ORANGE_500;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1),
      0px 0px 2px rgba(241, 86, 44, 0.1);
    outline: none;
  }

  &:disabled {
    background-color: $DARK_300;
    border: 1px solid $DARK_500;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);
    color: $WHITE_950;
    cursor: not-allowed;
  }
}

.btn-empty {
  &,
  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

.btn-icon {
  padding: 0.2em;
  border-radius: $BD_RADIUS_6;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: $DARK_700;
    border: 1px solid $DARK_500;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }

  &:active,
  &:focus {
    background-color: $DARK_900;
    border: 1px solid $DARK_700;
    outline: none;
  }
}

.btn-text {
  background-color: transparent;
  color: $ORANGE_500;

  &:hover {
    color: $ORANGE_700;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.6),
      0px 0px 10px rgba(241, 86, 44, 0.5);
  }

  &:active,
  &:focus {
    outline: none;
    color: $ORANGE_900;
  }

  &:disabled {
    color: $WHITE_950;
    text-shadow: none;
    cursor: not-allowed;
  }
}

.btn-textUnderline {
  @extend .btn-text;
  text-decoration: underline;
}

.btn-tab {
  @extend .btn-text;
  color: $WHITE_400;
  padding: 0 1.2em;
}

.btn-tab-active {
  @extend .btn-textUnderline;
  padding: 0 1.2em;
  color: $ORANGE_500 !important;
}

.btn-tab,
.btn-tab-active {
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
