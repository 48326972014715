@import "assets/styles/_colors";

.Confirmation {

    &-content {
        margin: 2rem auto 3rem;
    }

    &-buttons {
        display: flex;
        justify-content: space-between;

        button {

            background-color: $DARK_100;

            &.btn-primary {
                background-color: $ORANGE_500;
            }
        }


    }
}