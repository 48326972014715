@import "assets/styles/_colors";

.UnsavedChanges {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: none;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9;

    & > div {
        max-width: 24rem;
        display: flex;
        height: 100%;
        margin: auto;
        justify-content: center;
        flex-direction: column;
    }

    &-modal {
        position: relative;
        border-radius: 8px;
        padding: 1em;
        text-align: center;
        background: $DARK_500;
    }

    h2 {
        margin: 2rem auto 0;
    }

    p {
        margin: 2rem auto 3rem;
    }

    .closeButton {
        cursor: pointer;
        position: absolute;
        right: 1em;
        top: 1em;

        svg {
            fill: $WHITE_100;
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;

        button {
            background-color: $DARK_100;

            &.primary {
                background-color: $ORANGE_500;
            }
        }
    }

    &.active {
        display: flex;
    }
}
