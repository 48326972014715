@import "../../assets/styles/_colors";
@import "../../assets//styles/_fonts";

.NoResults {
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        color: $WHITE_700;
        @extend %regular-font-md;
    }
}
